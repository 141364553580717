import {PostsAPILink} from './data';
import PostProcessor from './components/PostProcessor';
import axios from 'axios';
import Slider from './components/Slider';

require('../scss/pages/index.scss');

window.addEventListener('load', ()=>{
  const LatestNews = document.getElementById('latest-news');
  axios.get(`${PostsAPILink}posts?per_page=2`).then((res)=>{
    PostProcessor(res.data, LatestNews);
  })
  .catch((err)=>{
    console.log(`Error retrieving posts - ${err}`)
  });
});