const NewsItem = (title, media, mediaType, date, id, fullWidth=false) => {

  const NI = document.createElement('div');
  NI.classList.add('rwxs-p-b-lg', 'rwxs-p-t-no-rsp');
  NI.classList.add(fullWidth ? 'rwx-split-full' : 'rwx-split-half');

  const link = `/post?articleId=${id}`;

  NI.innerHTML = `
    <div class='news-item ${fullWidth ? 'full-width' : ''}'>
      <div>
        ${
          mediaType === "vid" ? 
            `<div class='news-item-video rwxs-m-b-sm'><video width="320" height="240" controls><source src="${media}" type="video/mp4"></video></div>`
          :
            `<div class='news-item-image rwxs-m-b-sm'><img src='${media}'/ referrerpolicy="no-referrer"></div>`
        }
      </div>
      <div>
        <p class="rwxc-color-light-blue rwxt-uppercase rwxt-small">${mediaType === 'vid' ? 'video' : 'post'}</p>
        <a href='${link}' class="no-decoration rwxc-color-black rwxt-no-underline"><h6>${title}</h6></a>
        ${
          date ? 
            `<p class="rwxc-color-light-blue rwxt-small">${date}</p>`
          :
            `<p class='rwxt-uppercase rwxt-bold rwxc-color-light-blue'>read article</p>`
        }
      </div>
    </div>
  `;
  return NI;
}

export default NewsItem;