import {PostsAPILink} from '../data';
import axios from 'axios';
import NewsItem from './NewsItem';

const ImageExtensions = ['png', 'jpg', 'jpeg'];
const VideoExtensions = ['mp4'];

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const insertZero = (i) => {
  return parseInt(i) < 10 ? `0${i}` : i;
}

const parseDate = (d) => {
  let date = new Date(d);
  return `${months[date.getMonth()+1]} ${insertZero(date.getDate())}, ${date.getFullYear()}`
}

const PostProcessor = (posts, element, postPage=false) => {
  let media = [];
  for(let post of posts) {
    media.push(axios.get(`${PostsAPILink}media?parent=${post.id}`))
  }
  Promise.all(media).then((res2)=>{
    for(let [index,m] of res2.entries()) {
      let med, medType;
      if(m.data.length > 0) {
        if(ImageExtensions.some((s)=>m.data[0].mime_type.includes(s))) {
          med = m.data[0].source_url;
          medType = "img";
        }
        else if(VideoExtensions.some((s)=>m.data[0].mime_type.includes(s))) {
          med = m.data[0].source_url;
          medType = "vid";
        }
      }
      else {
        med = "/assets/test.png";
        medType = "img";
      }
      element.appendChild(NewsItem(posts[index].title.rendered, med, medType, postPage ? parseDate(posts[index].date) : false, posts[index].id, (postPage && index===0)))
    }
  })
}

export default PostProcessor;