import rwxSliders from 'roseworx/js/core/rwxSliders';

window.addEventListener('load', ()=>{
  const back = document.getElementById('slider-left')
  const forward = document.getElementById('slider-right');

  back.addEventListener('click', ()=>{
    window.rwx.sliders.goToSlide('slider', window.rwx.sliders.getIME('slider').currentSlide-1)
  });

  forward.addEventListener('click', ()=>{
    window.rwx.sliders.goToSlide('slider', window.rwx.sliders.getIME('slider').currentSlide+1)
  });

});